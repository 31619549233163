import type { FC, ReactNode } from 'react'
import Link from 'next/link'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubItem,
} from '@goatlab/react-ui'
import { ChevronDown } from 'lucide-react'

export interface SidebarMenuType {
  sectionTitle?: string
  shouldDisplay?: boolean
  menus: SidebarMenuItemInterface[]
}

export interface SidebarMenuItemInterface {
  href?: string
  name: string
  icon: ReactNode
  subMenu?: SidebarMenuType[]
  shouldDisplay?: boolean
  onClick?: () => void
}

interface SideBarMenuProps {
  menu: SidebarMenuType[]
}

export const AppSideBarMenu: FC<SideBarMenuProps> = ({ menu }) => {
  return menu.map((section, menuIndex) => {
    if (section.shouldDisplay === false) {
      return null
    }

    if (!section.sectionTitle) {
      return (
        <SidebarGroup key={menuIndex}>
          <SidebarGroupContent>
            <SidebarMenu>
              {section.menus.map((menu) => (
                <SidebarMenuItem key={menu.name}>
                  <SidebarMenuButton asChild>
                    <Link
                      className="flex items-center space-x-2"
                      href={menu.href || '#'}
                    >
                      {menu.icon}
                      <span>{menu.name}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      )
    }

    return (
      <SidebarGroup key={menuIndex}>
        <SidebarGroupLabel>{section.sectionTitle}</SidebarGroupLabel>

        <SidebarGroupContent className="ml-2">
          <SidebarMenu>
            {section.menus
              .filter((section) => section.shouldDisplay)
              .map((menu, index) => {
                if (menu.shouldDisplay === false) {
                  return null
                }

                if (menu.subMenu?.length) {
                  return (
                    <Collapsible
                      defaultOpen={false}
                      className="group/collapsible"
                      key={index}
                    >
                      <SidebarMenuItem>
                        <CollapsibleTrigger asChild>
                          <SidebarMenuButton>
                            <Link
                              className="flex items-center space-x-2"
                              href={'#'}
                            >
                              {menu.icon}
                              <span>{menu.name}</span>
                            </Link>

                            <ChevronDown className="ml-auto mr-4 transition-transform group-data-[state=open]/collapsible:rotate-180" />
                          </SidebarMenuButton>
                        </CollapsibleTrigger>

                        <CollapsibleContent>
                          <SidebarMenuSub>
                            {menu.subMenu.map(
                              (subMenuSection, subMenuIndex) => {
                                return subMenuSection.menus.map((subMenu) => {
                                  return (
                                    <SidebarMenuSubItem key={subMenuIndex}>
                                      <SidebarMenuButton asChild>
                                        <Link href={subMenu.href || '#'}>
                                          {subMenu.icon}
                                          <span>{subMenu.name}</span>
                                        </Link>
                                      </SidebarMenuButton>
                                    </SidebarMenuSubItem>
                                  )
                                })
                              },
                            )}
                          </SidebarMenuSub>
                        </CollapsibleContent>
                      </SidebarMenuItem>
                    </Collapsible>
                  )
                }

                return (
                  <SidebarMenuItem key={index}>
                    {menu.onClick ? (
                      <SidebarMenuButton onClick={menu.onClick}>
                        {menu.icon}
                        <span>{menu.name}</span>
                      </SidebarMenuButton>
                    ) : (
                      <SidebarMenuButton asChild>
                        <Link href={menu.href || '#'}>
                          {menu.icon}
                          <span>{menu.name}</span>
                        </Link>
                      </SidebarMenuButton>
                    )}
                  </SidebarMenuItem>
                )
              })}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
    )
  })
}
