import type { SidebarMenuType } from '@components/ui/sidebarMenu/AppSideBarMenu'
import type { FC } from 'react'
import { useState } from 'react'
import { AccountSwitcher } from '@components/account/AccountSwitcher'
import { DataCenterDialog } from '@components/account/pages/DataCenterDialog'
import { NewsHubsDialog } from '@components/account/pages/NewsHubsDialog'
import { usePagesNavigation } from '@components/account/pages/usePagesNavigation'
import { useProductsNavigation } from '@components/account/products/useProductsNavigation'
import { useAuth } from '@components/auth/store/auth.store'
import { AppSideBarMenu } from '@components/ui/sidebarMenu/AppSideBarMenu'
import { Strings } from '@goatlab/js-utils'
import {
  Separator,
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  useScrollHeight,
} from '@goatlab/react-ui'
import { env } from '@src/_env/env'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import {
  CalendarDays,
  CreditCard,
  FileBarChartIcon,
  HomeIcon,
  LayoutDashboard,
  NewspaperIcon,
  ShoppingCart,
  Store,
} from 'lucide-react'
import { AiOutlineMessage } from 'react-icons/ai'

export const menuItemIconStyle =
  'flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white'

export const AccountDrawerLeft: FC = () => {
  const { t } = useTypedTranslation(['common', 'accountSettings'])
  const stateRouter = useStateAwareRoutes()
  const { user } = useAuth()
  const [isShowingNewsHub, setIsShowingNewHubs] = useState(false)
  const [isShowingDataCenter, setIsShowingDataCenter] = useState(false)
  const { navbarOffset } = useScrollHeight()
  const productsNavigation = useProductsNavigation()
  const pagesNavigation = usePagesNavigation()
  const shouldDisplay = !!user?.account?.hasMarketplace

  const accountMenu: SidebarMenuType[] = [
    {
      sectionTitle: Strings.upperFirst(env.APP_NAME),
      menus: [
        {
          href: stateRouter.dynamic.ACCOUNT_HOME(),
          name: 'Home',
          icon: <HomeIcon className={menuItemIconStyle} />,
          shouldDisplay: true,
        },
        {
          href: stateRouter.dynamic.ACCOUNT_INBOX(),
          name: t('text-inbox'),
          icon: <AiOutlineMessage className={menuItemIconStyle} />,
          shouldDisplay: true,
        },
        {
          name: 'NewsHub',
          icon: <NewspaperIcon className={menuItemIconStyle} />,
          shouldDisplay: true,
          onClick: () => setIsShowingNewHubs(true),
        },
        {
          name: 'Data Center',
          icon: <FileBarChartIcon className={menuItemIconStyle} />,
          shouldDisplay: true,
          onClick: () => setIsShowingDataCenter(true),
        },
      ],
    },
    {
      sectionTitle: t('drawer-marketplace'),
      menus: [
        {
          href: stateRouter.dynamic.ACCOUNT_DASHBOARD(),
          name: t('text-dashboard'),
          icon: <LayoutDashboard className={menuItemIconStyle} />,
          shouldDisplay,
        },
        {
          href: stateRouter.dynamic.ACCOUNT_ORDERS(),
          name: t('text-orders'),
          icon: <ShoppingCart className={menuItemIconStyle} />,
          shouldDisplay: true,
        },
        {
          href: stateRouter.dynamic.ACCOUNT_PRODUCTS(),
          name: t('text-products'),
          icon: <Store className={menuItemIconStyle} />,
          shouldDisplay,
          subMenu: productsNavigation,
        },
        {
          href: stateRouter.dynamic.ACCOUNT_CALENDAR(),
          name: t('text-calendar'),
          icon: <CalendarDays className={menuItemIconStyle} />,
          shouldDisplay,
        },
        {
          href: stateRouter.dynamic.ACCOUNT_SETTINGS_PAYMENTS(),
          name: t('payments', { ns: 'accountSettings' }),
          icon: <CreditCard className={menuItemIconStyle} />,
          shouldDisplay: true,
        },
      ],
      shouldDisplay,
    },
    {
      sectionTitle: t('drawer-content'),
      menus: pagesNavigation,
      shouldDisplay,
    },
  ]

  return (
    <Sidebar
      className="bg-white"
      style={{
        top: `${navbarOffset + 55}px`,
      }}
    >
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarGroup>
              <SidebarGroupLabel className="mb-2">Accounts</SidebarGroupLabel>
              <AccountSwitcher />
            </SidebarGroup>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>

      <Separator />

      <SidebarContent>
        <AppSideBarMenu menu={accountMenu} />
      </SidebarContent>

      <NewsHubsDialog
        isOpen={isShowingNewsHub}
        onClose={() => setIsShowingNewHubs(false)}
      />
      <DataCenterDialog
        isOpen={isShowingDataCenter}
        onClose={() => setIsShowingDataCenter(false)}
      />
    </Sidebar>
  )
}
