import type { SidebarMenuType } from '@components/ui/sidebarMenu/AppSideBarMenu'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import { ActivitySquare, CalendarDays } from 'lucide-react'

export const useProductsNavigation = () => {
  const { t } = useTypedTranslation()
  const stateRouter = useStateAwareRoutes()

  const sharedSections: SidebarMenuType[] = [
    {
      sectionTitle: t('online-appointments', { ns: 'productSettings' }),
      menus: [
        {
          href: stateRouter.dynamic.ACCOUNT_PRODUCTS_APPOINTMENTS(),
          name: t('online-appointments', { ns: 'productSettings' }),
          icon: (
            <CalendarDays className={`mr-2 h-4 w-4 shrink-0 text-slate-400`} />
          ),
        },
      ],
    },
    // {
    //   sectionTitle: t('physical-products'),
    //   menus: [
    //     {
    //       href: stateRouter.dynamic.ACCOUNT_PRODUCTS_PHYSICAL(),
    //       name: t('physical-products'),
    //       icon: <Box className={`w-4 h-4 shrink-0 text-slate-400 mr-2`} />,
    //     },
    //   ],
    // },
    {
      sectionTitle: t('digital-products', { ns: 'productSettings' }),
      menus: [
        {
          href: stateRouter.dynamic.ACCOUNT_PRODUCTS_EXAMS(),
          name: t('medical-exams', { ns: 'productSettings' }),
          icon: (
            <ActivitySquare
              className={`mr-2 h-4 w-4 shrink-0 text-slate-400`}
            />
          ),
        },
        // {
        //   href: stateRouter.dynamic.ACCOUNT_SETTINGS_AVAILABILITY(),
        //   name: t('courses'),
        //   icon: (
        //     <GraduationCap className={`w-4 h-4 shrink-0 text-slate-400 mr-2`} />
        //   ),
        // },
        // {
        //   href: stateRouter.dynamic.ACCOUNT_PRODUCTS_APPOINTMENTS(),
        //   name: t('learning-paths'),
        //   icon: <Split className={`w-4 h-4 shrink-0 text-slate-400 mr-2`} />,
        // },
      ],
    },
    // {
    //   sectionTitle: t('Grouping'),
    //   menus: [
    //     {
    //       href: stateRouter.dynamic.ACCOUNT_SETTINGS_AVAILABILITY(),
    //       name: t('categories'),
    //       icon: (
    //         <SquareStack className={`w-4 h-4 shrink-0 text-slate-400 mr-2`} />
    //       ),
    //     },
    //     {
    //       href: stateRouter.dynamic.ACCOUNT_SETTINGS_AVAILABILITY(),
    //       name: t('Tags'),
    //       icon: <Tag className={`w-4 h-4 shrink-0 text-slate-400 mr-2`} />,
    //     },
    //   ],
    // },
  ]

  const organizationAccountSections: SidebarMenuType[] = [...sharedSections]

  return organizationAccountSections
}
