import type { AccountWithAccess } from '@components/auth/store/authStore.model'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import * as React from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useAuth } from '@components/auth/store/auth.store'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@components/ui/images/avatar'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Dialog,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  SidebarMenuButton,
} from '@goatlab/react-ui'
import { useAccountStore } from '@sodium/shared-frontend-schemas'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { cn } from '@utils/cn'
import { CheckIcon, ChevronsUpDown, PlusCircleIcon } from 'lucide-react'

const CreateSubAccountWizard = dynamic(() =>
  import('@components/account/create/CreateAccountWizard').then(
    (mod) => mod.CreateAccountWizard,
  ),
)

interface Group {
  label: string
  accounts: AccountWithAccess[]
}

export const AccountSwitcher: FC = () => {
  const [openDropdown, setOpenDropdown] = React.useState(false)
  const [showNewAccountDialog, setShowNewAccountDialog] = React.useState(false)
  const { selectedAccount } = useAccountStore()
  const { user } = useAuth()
  const router = useRouter()
  const routes = useStateAwareRoutes()

  const accountId: string | undefined = selectedAccount?.id

  const accounts: AccountWithAccess[] = []

  const collaborations = user?.account?.collaborations || []

  const personalAccounts: AccountWithAccess[] = user?.account
    ? [user?.account]
    : []

  for (const collaboration of collaborations) {
    if (!personalAccounts.some((acc) => acc?.id === collaboration.account.id)) {
      accounts.push(collaboration.account)
    }
  }

  const displayName =
    user?.account?.displayName ||
    `${user?.account?.firstName} ${user?.account?.lastName}`

  const groups: Group[] = [
    {
      label: 'Personal',
      accounts: personalAccounts,
    },
  ]

  useEffect(() => {
    if (accounts.length) {
      groups.push({
        label: 'Linked accounts',
        accounts,
      })
    }
  }, [accounts])

  const [selectedTeam, setSelectedTeam] = useState<AccountWithAccess>()

  useEffect(() => {
    const foundAccount = accounts.find((o) => o?.id === accountId)
    const foundPersonalAccount = personalAccounts.find(
      (a) => a?.id === accountId,
    )

    if (foundPersonalAccount) {
      setSelectedTeam(foundPersonalAccount)
    } else if (foundAccount) {
      setSelectedTeam(foundAccount)
    } else if (!accountId) {
      setSelectedTeam(personalAccounts[0])
    }
  }, [accountId, user, displayName])

  return (
    <DropdownMenu open={openDropdown} onOpenChange={setOpenDropdown}>
      <DropdownMenuTrigger asChild>
        <SidebarMenuButton>
          <Avatar className="mr-2 h-8 w-8">
            <AvatarImage
              src={
                selectedAccount?.profilePicture ||
                `https://avatar.vercel.sh/${selectedTeam?.displayName}.png`
              }
              alt={selectedTeam?.displayName || ''}
            />
            <AvatarFallback>SC</AvatarFallback>
          </Avatar>
          <div className="... overflow-hidden text-ellipsis whitespace-nowrap text-xs">
            {selectedTeam?.displayName}
          </div>
          <ChevronsUpDown className="ml-auto" />
        </SidebarMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="right"
        align="start"
        className="w-[--radix-popper-anchor-width]"
      >
        <Command className="z-600">
          <CommandInput placeholder="Search account..." />
          <CommandList>
            <CommandEmpty>No organization found.</CommandEmpty>
            {groups.map((group) => (
              <CommandGroup key={group.label} heading={group.label}>
                {group.accounts.map((acc) => (
                  <CommandItem
                    key={acc?.id || '*'}
                    onSelect={async () => {
                      setSelectedTeam(acc)

                      setOpenDropdown(false)
                      await router.push(
                        routes.dynamic.ACCOUNT_DASHBOARD({
                          accountId: acc?.id,
                        }),
                      )
                    }}
                    className="text-sm"
                  >
                    <Avatar className="mr-2 h-5 w-5">
                      <AvatarImage
                        src={
                          acc?.profilePicture ??
                          `https://avatar.vercel.sh/${acc?.id}.png`
                        }
                        alt={acc?.displayName || ''}
                      />
                      <AvatarFallback>ORG</AvatarFallback>
                    </Avatar>
                    {acc?.displayName}
                    <CheckIcon
                      className={cn(
                        'ml-auto h-4 w-4',
                        selectedTeam?.id === acc?.id
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </CommandList>
          <CommandSeparator />
          <CommandList>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  setOpenDropdown(false)
                  setShowNewAccountDialog(true)
                }}
                data-cy="create-organization"
              >
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add Account
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </DropdownMenuContent>
      <Dialog
        open={showNewAccountDialog}
        onOpenChange={setShowNewAccountDialog}
      >
        <CreateSubAccountWizard setDialogOpen={setShowNewAccountDialog} />
      </Dialog>
    </DropdownMenu>

    // <Dialog open={showNewAccountDialog} onOpenChange={setShowNewAccountDialog}>
    //   <Popover open={open} onOpenChange={setOpen}>
    //     <PopoverTrigger asChild>
    //       <Button
    //         id="accountSwitcher"
    //         variant="outline"
    //         role="combobox"
    //         aria-expanded={open}
    //         aria-label="Select a team"
    //         className={cn('flex-1 justify-between', className)}>

    //       </Button>
    //     </PopoverTrigger>
    //     <PopoverContent className="w-[200px] p-0">

    //     </PopoverContent>
    //   </Popover>
    //
    // </Dialog>
  )
}
