import type { SidebarMenuType } from '@components/ui/sidebarMenu/AppSideBarMenu'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { Globe, PanelTop } from 'lucide-react'

export const usePagesNavigation = () => {
  const stateRouter = useStateAwareRoutes()
  const { t } = useTypedTranslation(['accountSettings'])

  const sharedSections: SidebarMenuType['menus'] = [
    {
      href: stateRouter.dynamic.ACCOUNT_PAGES(),
      name: 'Páginas',
      icon: <PanelTop className={`mr-2 h-4 w-4 shrink-0 text-slate-400`} />,
      shouldDisplay: true,
    },
    // {
    //   href: stateRouter.dynamic.ACCOUNT_PAGES_MENUS(),
    //   name: 'Menus',
    //   icon: <MenuSquare className={`mr-2 h-4 w-4 shrink-0 text-slate-400`} />,
    //   shouldDisplay: true,
    // },
    {
      href: stateRouter.dynamic.ACCOUNT_SETTINGS_DOMAINS(),
      name: t('domains', { ns: 'accountSettings' }),
      icon: <Globe className={`mr-2 h-4 w-4 shrink-0 text-slate-400`} />,
      shouldDisplay: true,
    },
  ]

  return sharedSections
}
